.product-list {
  position: absolute;
  bottom: 15px;
  left: 0;

  width: 30vw;
  height: 50vh;
  z-index: 9000;
  padding: 10px;

  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14),
    0px 4px 8px 0px rgba(58, 56, 49, 0.1);

  &__header {
    font-size: 16px;
    font-weight: 600;

    i {
      cursor: pointer;

      &:hover {
        color: #ffc914;
      }
    }
  }

  &__main {
    height: calc(100% - 35px);
    overflow-y: auto;
    padding: 4px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }

    .product-card {
      display: flex;
      flex-direction: row;
      gap: 8px;

      padding: 4px;
      margin-bottom: 8px;
      border-bottom: 1px solid #e5e7e8;

      &:last-child {
        border-bottom: none;
      }

      &__image {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }

      &__info {
        p {
          margin-bottom: 2px;
          color: #414549;
        }

        .name {
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
        }

        .size {
          font-size: 10px;
          font-weight: 500;
        }

        .grey {
          color: #808b90;
        }

        .price {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}
