.mainLayout {
  // height: 100vh !important;
  // max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__header {
    background-color: #fff;
    position: fixed;
    padding: 0 30px;
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
    z-index: 1000 !important;

    &__logo {
      max-width: 180px;
      cursor: pointer;
    }

    i {
      cursor: pointer;
    }
  }

  &__children {
    padding-top: 50px;
    width: 100%;
    height: 100%;
    background-color: #fefdfa;
  }

  &__footer {
    padding: 15px 30px;

    a {
      color: #fff !important;
    }
  }
}
