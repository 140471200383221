.loginPage {
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  background-image: url(../../../public/assets/images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;

  .formLogin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 500px;
    height: 450px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    font-size: 16px;

    h1,
    label {
      color: #dedede;
    }

    .group {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      width: fit-content;
      margin: 0 auto;
      margin-top: 20px;

      input {
        width: 350px;
        padding: 6px 12px;
        border-radius: 10px;
        border: 1px solid #dfdfdf;
        background: #f1f1f1;

        &::-ms-reveal {
          display: none;
        }
      }

      &__password {
        position: relative;

        i {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          color: #000;
          cursor: pointer;
        }
      }

      .error {
        margin-bottom: 0;
        font-size: 14px;
        color: #fe0000;
      }
    }

    .forgot-pass {
      color: #939393;
      font-size: 12px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 0;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .error-login {
      text-align: left;
      margin-bottom: 0;
      font-size: 14px;
      color: #fe0000;
    }
  }
}
