@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);

  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px !important;
    height: 30px !important;
    border: 3px solid#f3f3f3; /* Light grey */
    border-top-color: #383636; /* Black */
    // border-top-color: #ffc918; /* Yellow */
    border-radius: 50%;
    animation: spinner 1s linear infinite;
  }
}
