.boxSize {
  position: absolute;
  width: 100vw;
  height: 98vh;
  z-index: 1000;

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__main {
    position: absolute;
    top: 35px;
    left: 60vw;

    width: 500px;
    height: 200px;
    z-index: 2000;
  }
}
