.sizeInfoPage {
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  // position: absolute;
  // top: 50%;
  // top: calc(50% + 30px);
  // transform: translateY(-50%);

  padding: 40px 100px;

  .container__type {
    background-color: #fff;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0rem 0.35rem 0.75rem rgba(30, 34, 40, 0.15);
    min-height: 80vh;

    .title {
      font-size: 22px;
      font-weight: 600;
    }

    .card-type {
      position: relative;
      padding: 8px;

      &__image {
        width: 100%;
        border: 2px solid transparent;
        border-radius: 5px;
        overflow: hidden;
        opacity: 0.8;
        cursor: pointer;
        transition: all ease-in 0.2s;
        // max-height: 33vh;
        object-fit: cover;

        &:hover {
          border: 2px solid rgba(30, 34, 40, 0.25);
          box-shadow: 0rem 0.35rem 0.75rem rgba(30, 34, 40, 0.25);
        }
      }

      &__name {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
    }

    .active img {
      border: 2px solid #ffc918;
      opacity: 1;

      &:hover {
        border: 2px solid #ffc918;
        box-shadow: 0rem 0.35rem 0.75rem rgba(30, 34, 40, 0.25);
      }
    }
  }
}

@media all and (min-width: 992px) and (max-width: 1280px) {
  .sizeInfoPage {
    padding: 20px 40px;
  }
}

@media all and (max-width: 992px) {
  .sizeInfoPage {
    padding: 20px 20px;
  }
}
