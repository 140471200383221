.virtualOption {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: start;

  width: 100%;
  padding: 15px 15px 0 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
  overflow: hidden;

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  .expand {
    color: #565e64;
    border: 1px solid #cdd1d4;
    border-radius: 50%;
    padding: 6px 7px;
  }

  .breadcrumb-container {
    .ant-breadcrumb {
      font-size: 12px;
    }
  }

  .category {
    position: absolute;
    width: 20px;
    opacity: 0;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #e5e7e8;
    margin: 8px auto;
  }

  .module-container {
    height: calc(100% - 180px) !important;

    .module-option {
      display: flex;
      flex-direction: row;
      align-content: start;
      flex-wrap: wrap;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 8px 4px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }

      .module-card {
        display: flex;
        flex-direction: column;

        padding: 8px;
        border-radius: 4px;
        box-shadow: none;
        transition: all ease-in-out 0.4s;

        &:hover {
          box-shadow: 0px 8px 20px 0px rgba(58, 56, 49, 0.1),
            0px -1px 8px 0px rgba(58, 56, 49, 0.1);

          .module-item__overlay {
            opacity: 1;
            cursor: pointer;
          }
        }

        .module-item {
          position: relative;
          transition: all ease-in-out 0.4s;

          &__overlay {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.05);
            transition: all ease-in-out 0.4s;

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: #fff;
            }

            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              color: #565e64;
              font-size: 18px;
            }
          }

          &:hover {
            .module-item__overlay {
              &::before {
                // background-color: rgba(255, 196, 0, 0.5);
                background-color: #ffdb62;
              }
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
          flex-grow: 1;

          &__name {
            font-size: 12px;
            font-weight: 800;
            color: #5c5b5b;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          &__size {
            font-size: 10px;
            font-weight: 500;
            color: #808b90;
            margin-bottom: 5px;
          }

          &__desc {
            font-size: 10px;
            font-weight: 500;
            color: #808b90;
            margin-bottom: 10px;

            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            overflow: hidden;
          }

          &__price {
            font-size: 12px;
            font-weight: 800;
            color: #5c5b5b;

            &__sign {
              font-size: 10px;
              font-weight: 600;
              color: #808b90;
              margin-left: 2px;
              vertical-align: top;
            }
          }
        }
      }
    }
  }

  .price-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;

    background-color: #fff;
    padding: 10px 15px;
    box-shadow: 0px 8px 20px 0px rgba(58, 56, 49, 0.1),
      0px -1px 8px 0px rgba(58, 56, 49, 0.1);

    .price-text {
      font-size: 15px;
      color: #868585;
      font-weight: 600;
    }

    .price-num {
      font-size: 18px;
      color: #414549;
      font-weight: 600;
    }

    .btn-prod-list {
      border: 1px solid #808b90;
      border-radius: 2px;
      background-color: transparent;

      color: #414549;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        border: 1px solid #ffc914;
        background-color: #fffae8;
      }
    }
  }
}
