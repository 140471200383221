.selectedItem {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  padding: 8px;

  &__header {
    margin-bottom: 8px;

    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  &__main {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 6px 6px 6px;
    border-radius: 10px;
    background: #fff;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }

    &_blank {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: 12px;
      color: #939393;
    }
  }

  &__footer {
    margin-top: 8px;

    i {
      font-size: 12px;
      padding: 6px;
      background-color: #ffc918;
      border-radius: 50%;
      margin-right: 6px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      color: #5c5b5b;
    }

    &__total {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}
