.more-texture {
  position: absolute;
  width: 100%;
  height: 100%;

  &__heading {
    font-size: 15px;
    font-weight: 600;
  }

  &__name {
    margin-left: 8px;
    font-size: 15px;
    font-weight: 600;
    color: #aab2b6;
  }

  &__close {
    cursor: pointer;

    &:hover {
      color: #ffd443;
    }
  }

  &__main {
    height: calc(100% - 75px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 4px 0 0 0;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
}
