body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #565e64;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label,
input,
button {
  font-family: "Montserrat", sans-serif !important;
  color: #565e64;
}
