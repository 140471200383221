.visibleContainer {
  position: absolute;
  top: 45px;
  left: 85vw;

  width: 140px;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 2px 0px rgba(118, 118, 118, 0.9);

  z-index: 2000;
}
