.selectBgPage {
  padding: 24px 100px;

  .bg-main {
    height: calc(100vh - 120px);
    overflow-x: hidden;
    border-radius: 8px;
    padding: 24px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);

    .title {
      font-size: 24px;
      font-weight: 600;
      color: #565e64;
    }

    .bg-container {
      height: calc(100% - 50px);
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }

      .bg-card {
        padding: 12px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: none;
        transition: all ease-in-out 0.3s;
        cursor: pointer;

        &:hover {
          background-color: #fffae8;
          box-shadow: 0px 8px 20px 0px rgba(58, 56, 49, 0.1),
            0px -1px 8px 0px rgba(58, 56, 49, 0.1);

          .bg-card__text {
            .name {
              opacity: 0;
            }

            .hover {
              opacity: 1;
            }

            i {
              border: 1px solid #ffdb62;
              background-color: #ffeeb6;
            }
          }
        }

        &__text {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;

          .name {
            opacity: 1;
            color: #565e64;
            font-size: 14px;
            font-weight: 600;
            transition: all ease-in-out 0.3s;
          }

          .hover {
            position: absolute;
            opacity: 0;
            color: #ffdb62;
            font-size: 14px;
            font-weight: 600;
            transition: all ease-in-out 0.3s;
          }

          i {
            border: 1px solid #cdd1d4;
            border-radius: 50%;
            padding: 8px 9px;
            background-color: transparent;
            transition: all ease-in-out 0.3s;
          }
        }

        &__image {
          width: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }
    }
  }
}

@media all and (min-width: 992px) and (max-width: 1280px) {
  .selectBgPage {
    padding: 20px 40px;
  }
}

@media all and (max-width: 992px) {
  .selectBgPage {
    padding: 20px 20px;
  }
}
