.kitchen-item {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0 0 8px 8px;
  padding: 8px 12px;

  &__title {
    font-size: 12px;
    font-weight: 600;
  }

  .kitchen-table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100% - 55px);

    &__content {
      height: calc(100% - 30px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
    }
  }

  &__details {
    border-bottom: 1px solid #f1efef;
    margin-bottom: 6px;

    .details__image {
      width: 55px;
      height: 55px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 5px;
    }

    .details__module {
      font-size: 9px;
      font-weight: 600;

      .module-container {
        overflow: hidden;
      }

      &__name {
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
        margin-bottom: 2px;
      }

      &__material,
      &__texture {
        margin-bottom: 0px;
      }

      &__material {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-weight: 500;
        padding: 0px 6px;
        color: #5c5b5b;
        background-color: #f1efef;
        margin: 2px 0;
        // margin-left: 4px;
        border-radius: 16px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: fit-content;
        max-width: 100%;
        cursor: context-menu;
      }

      &__texture {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-weight: 500;
        padding: 4px 6px;
        color: #5c5b5b;
        background-color: #f1efef;
        margin: 2px 0;
        // margin-left: 4px;
        border-radius: 16px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: fit-content;
        max-width: 100%;
        cursor: context-menu;

        .texture-icon {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      &__price {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        font-size: 10px;

        i {
          margin-right: 4px;
          color: #ffc918;
          background-color: #1b1b1b;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }

    .module-price {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .additional-text-container {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
  }
  .details__module__horizontal-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
  }

  &__totalprice {
    &__label {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 0;
    }

    &__number {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}
